<script>
import ValidationMixin from '~/mixins/validation'
import PasswordToggle from '~/components/PasswordToggle'
import SuggestedEmail from '~/components/forms/SuggestedEmail'

export default {
  components: {
    PasswordToggle,
    SuggestedEmail,
  },
  mixins: [ValidationMixin],
  props: {
    registerPath: {
      type: String,
      default: '',
    },
    onSubmit: {
      type: Function,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    loginFormPrefix: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      email: '',
      password: '',
      remember: false,
      passwordFieldType: 'password',
      suggestedEmail: '',
      prefilledByChrome: false,
      mounted: false,
    }
  },
  watch: {
    remember(rememberValue) {
      if (!this.$localStorage) return

      this.$localStorage.addItem('user.remember', rememberValue)

      if (rememberValue && this.email && this.email.length > 0) {
        this.$localStorage.addItem('user.email', this.email, 365)
      }
    },
    email() {
      if (!this.$localStorage) return

      if (this.remember && this.email && this.email.length > 0) {
        this.$localStorage.addItem('user.email', this.email, 365)
      }
    },
  },
  created() {
    if (!this.$localStorage) return

    this.remember = this.$localStorage.getItem('user.remember')

    if (this.remember) {
      this.email = this.$localStorage.getItem('user.email')
    }
  },
  mounted() {
    this.mounted = true

    setTimeout(this.checkIfPrefilledByChrome, 300)
  },
  methods: {
    async submit() {
      try {
        await this.onSubmit({
          email: this.email.trim(),
          password: this.password.trim(),
        })
      } catch (error) {
        this.$sentryCaptureException(error)
      }
    },
    togglePasswordField(type) {
      this.passwordFieldType = type
    },
    checkIfPrefilledByChrome() {
      // hacky solution for chrome password manager
      // https://stackoverflow.com/questions/55244590/autofill-does-not-trigger-onchange
      try {
        if (this.$refs.password?.matches(':autofill')) {
          this.prefilledByChrome = true
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
  },
}
</script>

<template>
  <div>
    <VeeForm v-slot="{ meta: formMeta }" @submit="submit">
      <div class="mb-16">
        <label for="email" class="form-label">
          {{ $label('form.email') }}
        </label>
        <VeeField
          v-slot="{ meta, field, errorMessage }"
          v-model="email"
          name="email"
          rules="required|email"
          validate-on-input
          @blur="checkEmail"
        >
          <input
            v-bind="field"
            :id="loginFormPrefix + 'email'"
            type="email"
            :class="[
              'form-control',
              { 'is-valid': meta.valid && meta.touched },
              { 'is-invalid': !meta.valid && meta.touched },
            ]"
            autocomplete="email"
            aria-describedby="email"
          />
          <div v-show="!meta.valid && meta.touched" class="invalid-feedback">
            {{ errorMessage }}
          </div>
        </VeeField>

        <SuggestedEmail
          :suggested-email="suggestedEmail"
          :update-email="updateEmail"
        />
      </div>

      <div class="mb-16">
        <label for="password" class="form-label">
          {{ $label('form.password') }}
        </label>

        <VeeField
          v-slot="{ meta, field, errorMessage }"
          v-model="password"
          name="password"
          rules="required|min:6"
          validate-on-input
        >
          <input
            v-bind="field"
            :id="loginFormPrefix + 'password'"
            ref="password"
            :type="passwordFieldType"
            autocomplete="current-password"
            :is-valid="meta.valid && meta.touched"
            :class="[
              'form-control',
              { 'is-valid': meta.valid && meta.touched },
              { 'is-invalid': !meta.valid && meta.touched },
            ]"
          />
          <div v-show="!meta.valid && meta.touched" class="invalid-feedback">
            {{ errorMessage }}
          </div>
        </VeeField>
      </div>

      <PasswordToggle :on-toggle="togglePasswordField" />

      <div class="form-check mt-24 mb-24">
        <input
          id="remember-me"
          v-model="remember"
          type="checkbox"
          class="form-check-input"
        />
        <label class="form-check-label" for="remember-me">
          {{ $label('user.signIn.rememberMe') }}
        </label>
      </div>

      <BaseButton
        :disabled="
          (isLoading || !formMeta.valid || !mounted) && !prefilledByChrome
        "
        :class="[{ loading: isLoading }, 'w-100']"
        variant="primary"
        type="submit"
        data-nw="login-submit"
      >
        {{ $label('common.login') }}
      </BaseButton>

      <NuxtLink
        v-if="registerPath"
        class="bc-mt-18 d-block"
        :to="{ path: registerPath, query: $route.query }"
        data-nw="login-to-register-link"
      >
        {{ $label('user.signIn.backToRegister') }}
      </NuxtLink>
    </VeeForm>

    <NuxtLink class="bc-mt-18 d-inline-block" to="/wachtwoord-vergeten">
      {{ $label('user.signIn.forgetPassword') }}
    </NuxtLink>
  </div>
</template>
