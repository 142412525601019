<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <template v-if="!inSlideMenu">
      <BasePageTitle
        v-if="isHeadingShown"
        :header="$label('user.signIn.title')"
        :subtitle="$label('user.signIn.subtitle')"
      />

      <div class="container">
        <div class="row d-flex justify-content-center">
          <div class="col col-md-6 col-lg-4">
            <LoginForm
              :is-loading="isLoading"
              :login-form-prefix="loginFormPrefix"
              :on-submit="onSubmit"
              :register-path="registerPath"
            />
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <LoginForm
        :is-loading="isLoading"
        :login-form-prefix="loginFormPrefix"
        :on-submit="onSubmit"
        :register-path="registerPath"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BasePageTitle from '~/components/BasePageTitle'
import LoginForm from '~/components/LoginForm'
import { BACKEND_ERRORS } from '~/utils/constants'

export default {
  components: {
    LoginForm,
    BasePageTitle,
  },
  props: {
    registerPath: {
      type: String,
      default: '',
    },
    isHeadingShown: {
      type: Boolean,
      required: true,
    },
    nextPagePath: {
      default: '',
      type: String,
    },
    onLoggedIn: {
      type: Function,
      default: () => {},
    },
    loginFormPrefix: {
      type: String,
      default: '',
    },
    inSlideMenu: {
      type: Boolean,
      default: false,
    },
    shouldPreventRedirect: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { signIn } = useAuthLib()
    const { closeDrawer } = useDrawer()

    return { signIn, closeDrawer }
  },
  data() {
    return {
      isLoading: false,
      originalRedirect: false,
    }
  },
  computed: {
    ...mapGetters({
      loggedInUser: 'loggedInUser',
    }),
    userFirstName() {
      return this.loggedInUser && this.loggedInUser.firstName
    },
  },
  methods: {
    async onSubmit({ email, password }) {
      try {
        this.isLoading = true

        const currentPageQuery = new URLSearchParams(
          this.$route.query,
        ).toString()
        const nextPage = `${this.nextPagePath}?${currentPageQuery}`

        await this.signIn({ email, password }, nextPage)

        this.onLoggedIn()

        if (this.inSlideMenu) {
          this.showLoggedInSuccessAlert()
        }
        this.closeDrawer()
        this.setActiveCampaign()
      } catch (error) {
        const errorLabel = this.$error.getLabel(error)

        const isValidationError = BACKEND_ERRORS.includes(errorLabel)
        const label = isValidationError
          ? `errors.${errorLabel}`
          : 'messages.errorOccuredMessage'

        this.$toast.error(this.$label(label))

        if (!isValidationError) {
          this.$sentryCaptureException(error)
        }
      } finally {
        this.isLoading = false
      }
    },
    setActiveCampaign() {
      if (window.vgo) {
        window.vgo('setEmail', this.loggedInUser.email)
        window.vgo('process')
      }
    },
    showLoggedInSuccessAlert() {
      const loginLabel = this.userFirstName
        ? this.$label('user.login.successMessageWithName', {
            firstName: this.userFirstName,
          })
        : this.$label('user.login.successMessageNoName')

      this.$toast.success(loginLabel)
    },
  },
}
</script>
